<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> Policies </b>
    </template>
    <b-card>
      <b-table striped hover responsive :items="items" :fields="fields">
        <template v-slot:cell(owner)="row">
          {{ row.item.herder + row.item.group }}
        </template>

        <template v-slot:cell(premium)="row">
          {{ row.item.quotation_amount.toLocaleString() }}
        </template>
      </b-table>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

export default {
  data() {
    return {
      fields: [
        { key: "id", sortable: true },
        { key: "owner", sortable: true },
        { key: "season", sortable: true },
        { key: "area", sortable: true },
        { key: "premium", sortable: true },
      ],
      items: null,
    };
  },
  methods: {
    loadQuotations: async function () {
      this.items = await db.quotations.toArray();
    },
  },
  created() {
    this.loadQuotations();
  },
};
</script>