import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '../components/Layout.vue'

import ErrorPage from '../views/Errors/Error.vue'

// import Home from '../views/Home/Home.vue'

import Sync from '../views/Sync/Sync.vue'

import Quote from '../views/Quote/Quote.vue'

import Herders from '../views/Herders/Herders.vue'
import Herder from '../views/Herders/Herder.vue'
// import HerderGroup from '../views/Herders/Group.vue'
import Beneficiary from '../views/Herders/Beneficiary.vue'
import TLUs from '../views/Herders/TLUs.vue'
import TLU from '../views/Herders/TLU.vue'
import TLUCompositions from '../views/Herders/TLUCompositions.vue'
import TLUComposition from '../views/Herders/TLUComposition.vue'

// import Groups from '../views/Groups/Groups.vue'
// import Group from '../views/Groups/Group.vue'
// import GroupHerders from '../views/Groups/Herders.vue'

import TLUTypes from '../views/TLUTypes/TLUTypes.vue'

import Sell from '../views/Sell/Sell.vue'

import Policies from '../views/Policies/Policies.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
      },
      {
        path: '/',
        name: 'Home',
        component: Sync
      },
      {
        path: '/sync',
        name: 'Sync',
        component: Sync
      },
      {
        path: '/quote',
        name: 'Quote',
        component: Quote
      },
      {
        path: '/herders',
        name: 'Herders',
        component: Herders
      },
      {
        path: '/herder',
        name: 'AddHerder',
        component: Herder
      },
      {
        path: '/herder/:id',
        name: 'EditHerder',
        component: Herder,
        props: true
      },
      // {
      //   path: '/herder/group/:herder_id',
      //   name: 'EditHerderGroup',
      //   component: HerderGroup,
      //   props: true
      // },
      {
        path: '/beneficiary/:id',
        name: 'Beneficiary',
        component: Beneficiary,
        props: true
      },
      // {
      //   path: '/groups',
      //   name: 'Groups',
      //   component: Groups
      // },
      // {
      //   path: '/group',
      //   name: 'AddGroup',
      //   component: Group
      // },
      // {
      //   path: '/group/:id',
      //   name: 'EditGroup',
      //   component: Group,
      //   props: true
      // },
      // {
      //   path: '/group/herders/:id',
      //   name: 'ListGroupHerders',
      //   component: GroupHerders,
      //   props: true
      // },
      {
        path: '/tlutypes',
        name: 'TLUTypes',
        component: TLUTypes
      },
      {
        path: '/sell',
        name: 'Sell',
        component: Sell
      },
      {
        path: '/tlus/:id',
        name: 'TLUs',
        component: TLUs,
        props: true
      },
      {
        path: '/tlu/add/:herder_id',
        name: 'AddTLUs',
        component: TLU,
        props: true
      },
      {
        path: '/tlu/edit/:id',
        name: 'EditTLUs',
        component: TLU,
        props: true
      },
      {
        path: '/tlu/compositions/:id',
        name: 'TLUCompositions',
        component: TLUCompositions,
        props: true
      },
      {
        path: '/tlu/composition/add/:tlu_id',
        name: 'AddTLUComposition',
        component: TLUComposition,
        props: true
      },
      {
        path: '/tlu/composition/edit/:id',
        name: 'EditTLUComposition',
        component: TLUComposition,
        props: true
      },
      {
        path: '/policies',
        name: 'Policies',
        component: Policies
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
