import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

async function saveTLUComposition(item, resource) {
  let data = await db.tlucompositions
    .where("id")
    .equals(parseInt(item.id))
    .first();

  if (data !== null && resource !== null && !(resource instanceof Array)) {
    db.tlucompositions.put({
      ...item,
      ...resource,
      date_synced: new Date().getTime()
    });
  }
}
async function syncTLUComposition(tokenStr, token, item) {
  let resource = null;

  let tlu = await db.tlus
    .where("id")
    .equals(parseInt(item.tlu_x_id))
    .first();

  if (tlu !== null && tlu.tlu_id !== null) {
    axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .get(
        `/api/v1/tlu_compositions?tlu_id=${tlu.tlu_id}&tlu_type_id=${item.tlu_type_id}`
      )
      .then((response) => {
        if (response.data == null || response.data.results == null) {
          console.info("syncTLUComposition:New TLU Composition, upload");

          //update fields
          item.tlu_composition_id = null;
          item.organisation_id = parseInt(token.organisation_id);
          item.herder_id = parseInt(tlu.herder_id);
          item.tlu_id = parseInt(tlu.tlu_id);
          item.record_version = null;

          //save
          axios
            .create({
              baseURL: window.ibliURL,
              headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .post("/api/v1/tlu_compositions", item)
            .then((response) => {
              resource = response.data.resource;

              try {
                saveTLUComposition(item, resource);

                console.info(
                  "syncTLUComposition:TLU Composition synced...."
                );
              } catch (error) {
                console.info("syncTLUComposition:saveTLU issue: " + error);
              }

              console.info("syncTLUComposition:TLU Composition Saved");
            })
            .catch((e) => {
              console.info("syncTLUComposition:TLU Composition not Saved");

              var errors = e.response.data.errors;
              if (errors instanceof Array) {
                errors.forEach((element) => {
                  console.error(
                    "syncTLUComposition:TLU Composition Upload Error: " +
                    element.message.toString()
                  );
                });
              }
            });
        } else {
          console.info(
            "syncTLUComposition:Existing TLU Composition, push update if changed before, otherwise ignore...."
          );
          resource = response.data.results[0];

          if (
            resource.record_version <= item.record_version ||
            item.record_version == null
          ) {
            //update fields
            item.tlu_composition_id = resource.tlu_composition_id;
            item.herder_id = resource.herder_id;
            item.organisation_id = parseInt(token.organisation_id);
            item.tlu_id = resource.tlu_id;
            item.record_version = resource.record_version;

            //save
            axios
              .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${tokenStr}` },
              })
              .put(
                "/api/v1/tlu_compositions/" + resource.tlu_composition_id,
                item
              )
              .then((response) => {
                resource = response.data.resource;

                try {
                  saveTLUComposition(item, resource);

                  console.info(
                    "syncTLUComposition:TLU Composition synced...."
                  );
                } catch (error) {
                  console.info(
                    "syncTLUComposition:saveTLU issue: " + error
                  );
                }

                console.info("syncTLUComposition:TLU Composition Saved");
              })
              .catch((e) => {
                console.info(
                  "syncTLUComposition:TLU Composition not Saved"
                );

                var errors = e.response.data.errors;
                if (errors instanceof Array) {
                  errors.forEach((element) => {
                    console.error(
                      "syncTLUComposition:TLU Composition Upload Error: " +
                      element.message.toString()
                    );
                  });
                }
              });
          }
        }
      })
      .catch((e) => {
        console.info(
          "syncTLUComposition:TLU Composition syncs did not succeed. " +
          e.toString() +
          " failed"
        );
      });
  } else {
    // console.info(
    //   "syncTLUComposition:TLU Composition not synced, do sync after herder synced"
    // );
    debounceLeading(syncTLUComposition(tokenStr, token, item), 5000);
  }
}
async function syncTLUCompositions(tokenStr, token, tlu) {
  let data = await db.tlucompositions
    .where("tlu_x_id")
    .equals(parseInt(tlu.id))
    .toArray();

  data.forEach((item) => {
    syncTLUComposition(tokenStr, token, item);
  });
}

export { syncTLUCompositions };