import axios from "axios";

import { db } from "@/store/db";


async function saveTLUType(item) {
    let data = await db.tlutypes
        .where("tlu_type_id")
        .equals(parseInt(item.tlu_type_id))
        .first();

    if (data !== null) {
        db.tlutypes.put({
            ...item,
            date_synced: new Date().getTime()
        });
    } else {
        db.tlutypes.add({
            ...item,
            date_synced: new Date().getTime()
        });
    }
}

async function pullTLUTypes(tokenStr,token) {
    await axios
        .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .get(
            "/api/v1/tlu_types?all=true&columns=tlu_type_id,tlu_type,tlu&organisation_id=" +
            token.organisation_id
        )
        .then((response) => {
            if (response.data == null || response.data.results == null) {
                console.info("pullTLUTypes:Missing Data");
                return;
            } else {
                console.info("pullTLUTypes:Syncing TLU Types....");
            }

            try {
                response.data.results.forEach((item) => {
                    saveTLUType(item);
                });

                console.info("pullTLUTypes:TLU Types synced....");
            } catch (error) {
                console.info("pullTLUTypes:saveTLUType issue: " + error);
            }
        })
        .catch((e) => {
            console.info(
                "pullTLUTypes:TLU Types syncs did not succeed. " +
                e.toString() +
                " failed"
            );
        });
}

export { pullTLUTypes }