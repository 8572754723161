import axios from "axios";

import { db } from "@/store/db";

async function saveProgramFinancier(item) {
    let data = await db.program_financiers
      .where("program_financier_id")
      .equals(parseInt(item.program_financier_id))
      .first();

    let financier = await db.organisations
      .where("organisation_id")
      .equals(parseInt(item.financier_id))
      .first();

    if (data !== null) {
      db.program_financiers.put({
        financier: financier.organisation_name,
        ...item,
        date_synced: new Date().getTime()
      });
    } else {
      db.program_financiers.add({
        financier: financier.organisation_name,
        ...item,
        date_synced: new Date().getTime()
      });
    }
  }
  async function pullProgramFinanciers(tokenStr,program_id) {
    axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .get(
        "/api/v1/program_financiers?all=true&program_financier_status=ACTIVE&program_id=" +
          program_id
      )
      .then((response) => {
        if (response.data == null || response.data.results == null) {
          console.info("pullProgramFinanciers:Missing Data");
          return;
        } else {
          console.info(
            "pullProgramFinanciers:Syncing Program Financiers ...."
          );
        }

        try {
          response.data.results.forEach((item) => {
            saveProgramFinancier(item);
          });

          console.info("pullProgramFinanciers:Program Financiers synced....");
        } catch (error) {
          console.info(
            "pullProgramFinanciers:saveProgramFinancier issue: " + error
          );
        }
      })
      .catch((e) => {
        console.info(
          "pullProgramFinanciers:Program Financier syncs did not succeed. " +
            e.toString() +
            " failed"
        );
      });
  }

  export {pullProgramFinanciers};