import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

import { loadAgent } from "./Agents";

async function saveBeneficiary(item, resource) {
    let data = await db.beneficiaries
        .where("id")
        .equals(parseInt(item.id))
        .first();

    if (data !== null && resource !== null && !(resource instanceof Array)) {
        db.beneficiaries.put({
            ...item,
            ...resource,
            date_synced: new Date().getTime()
        });
    }
}

async function addBeneficiary(tokenStr, token, herder, item) {
    console.info("New Beneficiary, upload");

    /*update fields*/
    item.beneficiary_id = null;
    item.organisation_id = parseInt(token.organisation_id);
    item.herder_id = parseInt(herder.herder_id);

    /*save*/
    await axios
        .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .post("/api/v1/beneficiaries", item)
        .then((response) => {
            let resource = response.data.resource;

            try {
                saveBeneficiary(item, resource);

                console.info("syncBeneficiary:Beneficiary synced....");
            } catch (error) {
                console.info(
                    "syncBeneficiary:saveBeneficiary issue: " + error
                );
            }

            console.info("syncBeneficiary:Beneficiary Saved");
        })
        .catch((e) => {
            console.info("syncBeneficiary:Beneficiary not Saved");

            var errors = e.response.data.errors;
            if (errors instanceof Array) {
                errors.forEach((element) => {
                    console.error(
                        "syncBeneficiary:Beneficiary Upload Error: " +
                        element.message.toString()
                    );
                });
            }
        });
}

async function updateBeneficiary(tokenStr, token, item, response) {
    console.info(
        "syncBeneficiary:Existing Beneficiary, push update if changed before, otherwise ignore...."
    );
    let resource = response.data.results[0];

    if (
        resource.record_version <= item.record_version ||
        item.record_version == null
    ) {
        /*update fields*/
        item.beneficiary_id = resource.beneficiary_id;
        item.herder_id = resource.herder_id;
        item.organisation_id = parseInt(token.organisation_id);
        item.record_version = resource.record_version;

        /*save*/
        await axios
            .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .put(
                "/api/v1/beneficiaries/" + resource.beneficiary_id,
                item
            )
            .then((response) => {
                let resource = response.data.resource;

                try {
                    saveBeneficiary(item, resource);

                    console.info("syncBeneficiary:Beneficiary synced....");
                } catch (error) {
                    console.info(
                        "syncBeneficiary:saveBeneficiary issue: " + error
                    );
                }

                console.info("syncBeneficiary:Beneficiary Saved");
            })
            .catch((e) => {
                console.info("syncBeneficiary:Beneficiary not Saved");

                var errors = e.response.data.errors;
                if (errors instanceof Array) {
                    errors.forEach((element) => {
                        console.error(
                            "syncBeneficiary:Beneficiary Upload Error: " +
                            element.message.toString()
                        );
                    });
                }
            });
    }
}

async function syncBeneficiary(tokenStr, token, item) {
    let agent = await loadAgent();

    if (agent != null && agent.agent_id>0) {
        let herder = await db.herders
            .where("id")
            .equals(parseInt(item.herder_x_id))
            .first();

        if (herder.herder_id !== null && item !== null) {
            await axios
                .create({
                    baseURL: window.ibliURL,
                    headers: { Authorization: `Bearer ${tokenStr}` },
                })
                .get(`/api/v1/beneficiaries?herder_id=${herder.herder_id}`)
                .then((response) => {
                    if (response.data == null || response.data.results == null) {
                        addBeneficiary(tokenStr, token, herder, item);
                    } else {
                        updateBeneficiary(tokenStr, token, item, response);
                    }
                })
                .catch((e) => {
                    console.info(
                        "syncBeneficiary:Beneficiary syncs did not succeed. " +
                        e.toString() +
                        " failed"
                    );
                });
        } else {
            // console.info(
            //     "syncBeneficiary:Beneficiary not synced, do sync after herder synced"
            // );
            debounceLeading(syncBeneficiary(tokenStr, token, item), 5000);
        }
    } else {
        // console.info("syncBeneficiary: Agent NOT loaded.");
        debounceLeading(syncBeneficiary(tokenStr, token, item), 5000);
    }
}

async function uploadBeneficiaries(tokenStr, token) {
    let agent = await loadAgent();

    if (agent != null && agent.agent_id>0) {
        let data = await db.beneficiaries.toArray();

        data.forEach((item) => {
            syncBeneficiary(tokenStr, token, item);
        });
    } else {
        // console.info("uploadBeneficiaries: Agent NOT loaded.");
        debounceLeading(uploadBeneficiaries(tokenStr, token), 5000);
    }
}

export { uploadBeneficiaries };