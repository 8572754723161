import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

import { loadAgent } from "./Agents";

async function saveHerderGroup(item, resource) {
  let data = await db.groupherders
    .where("id")
    .equals(parseInt(item.id))
    .first();

  if (data !== null && resource !== null && !(resource instanceof Array)) {
    db.groupherders.put({
      ...item,
      ...resource,
      date_synced: new Date().getTime()
    });
  }
}

async function addHerderGroup(tokenStr, token, herder, group, item) {
  console.info("syncHerderGroup:New Herder Group, upload");

  //update fields
  item = {
    id: item.id,
    herder_id: herder.herder_id,
    group_id: group.group_id,
    group_herder_status: "ACTIVE",
    organisation_id: parseInt(token.organisation_id),
  };

  //save
  await axios
    .create({
      baseURL: window.ibliURL,
      headers: { Authorization: `Bearer ${tokenStr}` },
    })
    .post("/api/v1/group_herders", item)
    .then((response) => {
      let resource = response.data.resource;

      try {
        saveHerderGroup(item, resource);

        console.info("syncHerderGroup:Herder Group synced....");
      } catch (error) {
        console.info(
          "syncHerderGroup:saveHerderGroup issue: " + error
        );
      }

      console.info("syncHerderGroup:Herder Group Saved");
    })
    .catch((e) => {
      console.info("syncHerderGroup:Herder Group not Saved");

      var errors = e.response.data.errors;
      if (errors instanceof Array) {
        errors.forEach((element) => {
          console.error(
            "syncHerderGroup:Herder Group Upload Error: " +
            element.message
          );
        });
      }
    });
}

async function updateHerderGroup(tokenStr, item, response) {
  console.info(
    "syncHerderGroup:Existing Herder Group, push update if changed before, otherwise ignore...."
  );
  let resource = response.data.results[0];

  if (resource.record_version <= item.record_version) {
    //
    item.group_herder_status = "ACTIVE";

    //save
    await axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .put("/api/v1/group_herders/" + resource.group_herder_id, item)
      .then((response) => {
        resource = response.data.resource;

        try {
          saveHerderGroup(item, resource);

          console.info("syncHerderGroup:Herder Group synced....");
        } catch (error) {
          console.info(
            "syncHerderGroup:saveHerderGroup issue: " + error
          );
        }

        console.info("syncHerderGroup:Herder Group Saved");
      })
      .catch((e) => {
        console.info("syncHerderGroup:Herder Group not Saved");

        var errors = e.response.data.errors;
        if (errors instanceof Array) {
          errors.forEach((element) => {
            console.error(
              "syncHerderGroup:Herder Group Upload Error: " +
              element.message
            );
          });
        }
      });
  }
}

async function syncHerderGroup(tokenStr, token, item) {

  let herder = await db.herders
    .where("id")
    .equals(parseInt(item.herder_x_id))
    .first();
  let group = await db.groups
    .where("id")
    .equals(parseInt(item.group_x_id))
    .first();

  await axios
    .create({
      baseURL: window.ibliURL,
      headers: { Authorization: `Bearer ${tokenStr}` },
    })
    .get(
      `/api/v1/group_herders?group_id=${group.group_id}&herder=${herder.herder_id}`
    )
    .then((response) => {
      if (response.data == null || response.data.results == null) {
        addHerderGroup(tokenStr, token, herder, group, item);
      } else {
        updateHerderGroup(tokenStr, item, response);
      }
    })
    .catch((e) => {
      console.info(
        "syncHerderGroup:Herder Group syncs did not succeed. " +
        e.toString() +
        " failed"
      );
    });
}
async function uploadHerderGroups(tokenStr, token) {
  let agent = await loadAgent();

  if (agent != null && agent.agent_id>0) {
    let data = await db.groupherders.toArray();

    data.forEach((item) => {
      syncHerderGroup(tokenStr, token, item);
    });
  } else {
    // console.info("uploadHerderGroups: Agent NOT loaded.");
    debounceLeading(uploadHerderGroups(tokenStr, token), 5000);
  }
}

export { uploadHerderGroups };