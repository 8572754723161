<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> TLU Compositions </b>
    </template>
    <router-link class="btn mr-1 btn-primary btn-sm" :to="'/tlu/composition/add/' + this.id">
      New TLU
    </router-link>
    <b-card>
      <b-table striped hover :items="items" :fields="fields">
        <template v-slot:cell(actions)="row">
          <router-link
                :to="getEditPath(row.item)"
                title="Edit"
              >
                Edit</router-link
              >
        </template>
      </b-table>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

export default {
  props:["id"],
  data() {
    return {
      fields: [
        { key: "tlu_type", sortable: true },
        { key: "quantity", sortable: true },
        { key: "actions" },
      ],
      items: null,
    };
  },
  methods: {
    loadTLUCompositions: async function () {
      this.items = await db.tlucompositions.where("tlu_x_id").equals(parseInt(this.id)).toArray();
    },
    getEditPath(item) {
      return "/tlu/composition/edit/" + item.id;
    },
  },
  created() {
    this.loadTLUCompositions();
  },
};
</script>