import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

import { loadAgent } from "./Agents";

async function saveGroup(item, resource) {
  let data = await db.groups.where("id").equals(parseInt(item.id)).first();

  if (data !== null && resource !== null && !(resource instanceof Array)) {
    db.groups.put({
      ...item,
      ...resource,
      date_synced: new Date().getTime()
    });
  }
}

async function addGroup(tokenStr, token, agent, item) {
  console.info("New Group, upload");

  //update fields
  item.group_id = null;
  item.agent_id = agent.agent_id;
  item.organisation_id = parseInt(token.organisation_id);

  //save
  await axios
    .create({
      baseURL: window.ibliURL,
      headers: { Authorization: `Bearer ${tokenStr}` },
    })
    .post("/api/v1/groups", item)
    .then((response) => {
      let resource = response.data.resource;

      try {
        saveGroup(item, resource);

        console.info("syncGroup:Group synced....");
      } catch (error) {
        console.info("syncGroup:saveGroup issue: " + error);
      }

      console.info("syncGroup:Group Saved");
    })
    .catch((e) => {
      console.info("syncGroup:Group not Saved");

      var errors = e.response.data.errors;
      if (errors instanceof Array) {
        errors.forEach((element) => {
          console.error(
            "syncGroup:Group Upload Error: " + element.message
          );
        });
      }
    });
}

async function updateGroup(tokenStr,item,response){
  console.info(
    "syncGroup:Existing Group, push update if changed before, otherwise ignore...."
  );
  let resource = response.data.results[0];

  if (resource.record_version <= item.record_version) {
    //save
    await axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .put("/api/v1/groups/" + resource.group_id, item)
      .then((response) => {
        resource = response.data.resource;

        try {
          saveGroup(item, resource);

          console.info("syncGroup:Group synced....");
        } catch (error) {
          console.info("syncGroup:saveGroup issue: " + error);
        }

        console.info("syncGroup:Group Saved");
      })
      .catch((e) => {
        console.info("syncGroup:Group not Saved");

        var errors = e.response.data.errors;
        if (errors instanceof Array) {
          errors.forEach((element) => {
            console.error(
              "syncGroup:Group Upload Error: " + element.message
            );
          });
        }
      });
  }
}

async function syncGroup(tokenStr, token, item) {
  let agent = await loadAgent();

  if (agent != null && agent.agent_id>0) {
    await axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .get(
        `/api/v1/groups?group_number=${item.group_number}&organisation_id=${token.organisation_id}`
      )
      .then((response) => {
        if (response.data == null || response.data.results == null) {
          addGroup(tokenStr, token, agent, item);
        } else {
          updateGroup(tokenStr,item,response);
        }
      })
      .catch((e) => {
        console.info(
          "syncGroup:Group syncs did not succeed. " +
          e.toString() +
          " failed"
        );
      });
  }
}

async function uploadGroups(tokenStr, token) {
  let agent = await loadAgent();

  if (agent != null && agent.agent_id>0) {
    let data = await db.groups.toArray();

    data.forEach((item) => {
      syncGroup(tokenStr, token, item);
    });
  } else {
    // console.info("uploadGroups: Agent NOT loaded.");
    debounceLeading(uploadGroups(tokenStr, token), 5000);
  }
}

export { uploadGroups };