<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> Beneficiary </b>
    </template>
    <b-card
      :title="
        'Name: ' +
        (this.form.first_name != null
          ? this.form.first_name + ' ' + (this.form.second_name || '')
          : '')
      "
    >
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-first_name"
              label="First Name:"
              label-for="input-first_name"
            >
              <b-form-input
                id="input-first_name"
                class="border-input"
                type="text"
                v-model="form.first_name"
                required
                placeholder="First Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-second_name"
              label="Second Name:"
              label-for="input-second_name"
            >
              <b-form-input
                id="input-second_name"
                class="border-input"
                type="text"
                v-model="form.second_name"
                required
                placeholder="Second Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-phone_number"
              label="Mobile Number:"
              label-for="input-phone_number"
            >
              <b-form-input
                id="input-phone_number"
                class="border-input"
                type="text"
                v-model="form.phone_number"
                required
                placeholder="Mobile Number"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-email_address"
              label="Email:"
              label-for="input-email_address"
            >
              <b-form-input
                id="input-email_address"
                class="border-input"
                type="text"
                v-model="form.email_address"
                placeholder="Email"
              ></b-form-input>
            </b-form-group>
          </b-col>          
        </b-form-row>

        <b-form-row>         
          <b-col>
            <b-form-group
              id="input-group-id_type"
              label="ID Type:"
              label-for="input-id_type"
            >
              <b-form-select
                id="input-id_type"
                class="border-input"
                v-model="form.id_type"
                :options="idTypes"
                required
                placeholder="Enter ID Type"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-id_number"
              label="ID Number:"
              label-for="input-id_number"
            >
              <b-form-input
                id="input-id_number"
                class="border-input"
                type="text"
                v-model="form.id_number"
                required
                placeholder="ID Number"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row> 

        <b-form-row>         
          <b-col>
            <b-form-group
              id="input-group-beneficiary_status"
              label="Status:"
              label-for="input-beneficiary_status"
            >
              <b-form-select
                id="input-beneficiary_status"
                class="border-input"
                v-model="form.beneficiary_status"
                :options="statuses"
                required
                placeholder="Enter Status"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-form-row>

        <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

let toast = null;

export default {
  name: "herder",
  props: ["id"],
  data() {
    return {
      form: {
        beneficiary_id: null,
        herder_id:null,
        first_name: null,
        second_name: null,
        email_address:null,
        phone_number:null,
        id_type:null,
        id_number:null,
        beneficiary_status: null,
        record_version: null,
      },
      organisations: [],
      agents: [],
      areas: [],
      show: true,
      loading: false,
      response: null,
      error: null,
      statuses: [
        { value: null, text: "-select one-" },
        { value: "ACTIVE", text: "ACTIVE" },
        { value: "IN-ACTIVE", text: "IN-ACTIVE" },
      ],
      genders: [
        { value: null, text: "-select one-" },
        { value: "FEMALE", text: "FEMALE" },
        { value: "MALE", text: "MALE" },
      ],
      ageBrackets: [
        { value: null, text: "-select one-" },
        { value: "18 -25", text: "18 -25" },
        { value: "26 - 35", text: "26 - 35" },
        { value: "36 - 45", text: "36 - 45" },
        { value: "46 - 55", text: "46 - 55" },
        { value: "56 - 65", text: "56 - 65" },
        { value: "66 - 75", text: "66 - 75" },
        { value: "76 and above", text: "76 and above" },
      ],
      idTypes: [
        { value: null, text: "-select one-" },
        { value: "NATIONAL_ID", text: "NATIONAL ID" },
      ],
      services: [],
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      toast = this.$bvToast;

      this.form.herder_x_id = parseInt(this.id);

      if (this.form.id != null || this.form.id != undefined) {
        //update
        db.beneficiaries
          .put(this.form)
          .then(function () {
            toast.toast("Saved.", {
              duration: 5000,
              position: "top-center",
              title: "Beneficiary",
              variant: "success",
            });
          })
          .catch(function () {
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "Beneficiary",
              variant: "error",
            });
          });
      } else {
        //add
        db.beneficiaries
          .add(this.form)
          .then(function () {
            toast.toast("Saved.", {
              duration: 5000,
              position: "top-center",
              title: "Beneficiary",
              variant: "success",
            });
          })
          .catch(function () {
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "Beneficiary",
              variant: "error",
            });
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();

      // Reset our form values
      this.applyData();

      this.api_name = "";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    applyData() {
      if (this.response != null && this.response != undefined) {
        this.form.beneficiary_id = this.response.resource.beneficiary_id;
        this.form.herder_id = this.response.resource.herder_id;
        this.form.first_name = this.response.resource.first_name;
        this.form.second_name = this.response.resource.second_name;
        this.form.email_address = this.response.resource.email_address;
        this.form.phone_number = this.response.resource.phone_number;
        this.form.id_type = this.response.resource.id_type;
        this.form.id_number = this.response.resource.id_number;
        this.form.beneficiary_status = this.response.resource.beneficiary_status;
        this.form.record_version = this.response.resource.record_version;
        }
    },
    async fetchData() {
      if (this.id != null || this.id != undefined) {
        let data = await db.beneficiaries
          .where("herder_x_id")
          .equals(parseInt(this.id))
          .first();
        if (data != null) {
          this.form = data;
        }
      }
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
};
</script>