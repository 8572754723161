<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> Herder </b>
    </template>
    <b-card
      :title="
        'Name: ' +
        (this.form.first_name != null
          ? this.form.first_name + ' ' + (this.form.second_name || '')
          : '')
      "
    >
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-area_id"
              label="Area:"
              label-for="input-area_id"
            >
              <b-form-select
                id="input-area_id"
                class="border-input"
                v-model="form.area_id"
                :options="areas"
                required
                placeholder="Enter Area"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-herder_number"
              label="Herder Number:"
              label-for="input-herder_number"
            >
              <b-form-input
                id="input-herder_number"
                class="border-input"
                type="text"
                v-model="form.herder_number"                
                placeholder="Herder Number"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-first_name"
              label="First Name:"
              label-for="input-first_name"
            >
              <b-form-input
                id="input-first_name"
                class="border-input"
                type="text"
                v-model="form.first_name"
                required
                placeholder="First Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-second_name"
              label="Second Name:"
              label-for="input-second_name"
            >
              <b-form-input
                id="input-second_name"
                class="border-input"
                type="text"
                v-model="form.second_name"
                required
                placeholder="Second Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-phone_number"
              label="Mobile Number:"
              label-for="input-phone_number"
            >
              <b-form-input
                id="input-phone_number"
                class="border-input"
                type="text"
                v-model="form.phone_number"
                required
                placeholder="Mobile Number"                
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-email_address"
              label="Email:"
              label-for="input-email_address"
            >
              <b-form-input
                id="input-email_address"
                class="border-input"
                type="text"
                v-model="form.email_address"
                placeholder="Email"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-id_type"
              label="ID Type:"
              label-for="input-id_type"
            >
              <b-form-select
                id="input-id_type"
                class="border-input"
                v-model="form.id_type"
                :options="idTypes"
                placeholder="Enter ID Type"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-id_number"
              label="ID Number:"
              label-for="input-id_number"
            >
              <b-form-input
                id="input-id_number"
                class="border-input"
                type="text"
                v-model="form.id_number"
                placeholder="ID Number"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-gender"
              label="Gender:"
              label-for="input-gender"
            >
              <b-form-select
                id="input-gender"
                class="border-input"
                v-model="form.gender"
                :options="genders"
                required
                placeholder="Enter Gender"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-age"
              label="Age:"
              label-for="input-age"
            >
              <b-form-select
                id="input-age"
                class="border-input"
                v-model="form.age"
                :options="ageBrackets"
                required
                placeholder="Enter Age"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-herder_status"
              label="Status:"
              label-for="input-herder_status"
            >
              <b-form-select
                id="input-herder_status"
                class="border-input"
                v-model="form.herder_status"
                :options="statuses"
                required
                placeholder="Enter Status"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col> </b-col>
        </b-form-row>
        <b-overlay
          :show="busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
        </b-overlay>
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

let toast = null;

export default {
  name: "herder",
  props: ["id"],
  data() {
    return {
      form: {
        herder_id: null,
        organisation_id: null,
        agent_id: null,
        herder_number: null,
        area_id: null,
        first_name: null,
        second_name: null,
        email_address: null,
        phone_number: null,
        id_type: null,
        id_number: null,
        gender: null,
        age: null,
        herder_status: null,
        record_version: null,
      },
      organisations: [],
      agents: [],
      areas: [],
      busy: false,
      show: true,
      loading: false,
      response: null,
      error: null,
      statuses: [
        { value: null, text: "-select one-" },
        { value: "ACTIVE", text: "ACTIVE" },
        { value: "IN-ACTIVE", text: "IN-ACTIVE" },
      ],
      genders: [
        { value: null, text: "-select one-" },
        { value: "FEMALE", text: "FEMALE" },
        { value: "MALE", text: "MALE" },
      ],
      ageBrackets: [
        { value: null, text: "-select one-" },
        { value: "18 -25", text: "18 -25" },
        { value: "26 - 35", text: "26 - 35" },
        { value: "36 - 45", text: "36 - 45" },
        { value: "46 - 55", text: "46 - 55" },
        { value: "56 - 65", text: "56 - 65" },
        { value: "66 - 75", text: "66 - 75" },
        { value: "76 and above", text: "76 and above" },
      ],
      idTypes: [
        { value: null, text: "-select one-" },
        { value: "NATIONAL_ID", text: "NATIONAL ID" },
      ],
      services: [],
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();

      let that = this;
      let router=this.$router;

      this.busy = true;

      toast = this.$bvToast;

      if (this.id != null || this.id != undefined) {
        this.form.id = parseInt(this.id);
        //update
        db.herders
          .put(this.form)
          .then(function () {
            setTimeout(function () {
              toast.toast("Saved.", {
                duration: 5000,
                position: "top-center",
                title: "Herders",
                variant: "success",
              });
              router.push("/herders");
            }, 2000);
          })
          .catch(function () {
            that.busy = false;
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "Herders",
              variant: "error",
            });
          });
      } else {
        let herder = await db.herders
          .where("phone_number")
          .equals(that.form.phone_number)
          .first();

        if (herder != null) {
          that.busy = false;
          toast.toast("Save Failed. Herder with the same phone number exists.", {
            duration: 5000,
            position: "top-center",
            title: "Herders",
            variant: "error",
          });
          return;
        }

        //add
        db.herders
          .add(this.form)
          .then(function () {
            setTimeout(function () {
              toast.toast("Saved.", {
                duration: 5000,
                position: "top-center",
                title: "Herders",
                variant: "success",
              });
              router.push("/herders");
            }, 2000);
          })
          .catch(function () {
            that.busy = false;
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "Herders",
              variant: "error",
            });
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();

      // Reset our form values
      this.fetchData();

      this.api_name = "";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async fetchData() {
      if (this.id != null || this.id != undefined) {
        let data = await db.herders
          .where("id")
          .equals(parseInt(this.id))
          .first();
        if (data != null) {
          this.form = data;
        }
      }
    },
    async loadAreas() {
      let data = await db.areas.toArray();

      this.areas.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.areas.push({
          value: element.area_id,
          text: element.parent_area_name + " " + element.area_name,
        });
      });
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadAreas();
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
};
</script>