import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

import { loadAgent } from "./Agents";

async function saveArea(item) {
    let data = await db.areas
        .where("agent_area_id")
        .equals(parseInt(item.agent_area_id))
        .first();

    if (data !== null) {
        db.areas.put({
            ...item,
            date_synced: new Date().getTime()
        });
    } else {
        db.areas.add({
            ...item,
            date_synced: new Date().getTime()
        });
    }
}
async function pullAreas(tokenStr) {
    let agent = await loadAgent();

    if (agent != null && agent.agent_id>0) {
        await axios
            .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .get("/api/v1/agent_areas?all=true&agent_id=" + agent.agent_id)
            .then((response) => {
                if (response.data == null || response.data.results == null) {
                    console.info("pullAreas:Missing Data");
                    return;
                } else {
                    console.info("pullAreas:Syncing Areas....");
                }

                try {
                    response.data.results.forEach((item) => {
                        saveArea(item);
                    });

                    console.info("pullAreas:Areas synced....");
                } catch (error) {
                    console.info("pullAreas:saveArea issue: " + error);
                }
            })
            .catch((e) => {
                console.info(
                    "pullAreas:Areas sync did not succeed. " +
                    e.toString() +
                    " failed"
                );
            });
    } else {
        // console.info("pullAreas:Areas NOT synced, Agent NOT loaded.");
        debounceLeading(pullAreas(tokenStr), 15000);
    }
}

export { pullAreas };