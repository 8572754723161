<template>
  <div>
    <b-card title="Quote" tag="article">
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-area"
              label="Area:"
              label-for="input-area_id"
            >
              <b-form-select
                id="input-area_id"
                class="border-input"
                v-model="form.area_id"
                :options="areas"
                required
                placeholder="Enter Area ID"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              id="input-group-season"
              label="Season:"
              label-for="input-season"
            >
              <b-form-select
                id="input-season"
                class="border-input"
                v-model="form.season_id"
                :options="seasons"
                required
                placeholder="Enter Season"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row v-for="tlutype in tlutypes" :key="tlutype.tlu_type_id">
          <b-col>
            <b-form-group
              :id="'input-group-tlu_type_' + tlutype.tlu_type_id"
              label="Livestock:"
              :label-for="'input-tlu_type_' + tlutype.tlu_type_id"
            >
              <b-form-select
                :id="'input-tlu_type_' + tlutype.tlu_type_id"
                class="border-input"
                v-model="form.tlu_type_id[tlutype.tlu_type_id]"
                :options="tlutypeOptions"
                placeholder="Select TLU Type"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :id="'input-group-quantity_' + tlutype.tlu_type_id"
              label="Quantity:"
              :label-for="'input-quantity' + tlutype.tlu_type_id"
            >
              <b-form-input
                :id="'input-quantity_' + tlutype.tlu_type_id"
                class="border-input"
                v-model="form.quantity[tlutype.tlu_type_id]"
                type="number"
                placeholder="Enter Quantity"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-button type="submit" variant="primary">Submit</b-button> &nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
      <b-card class="mt-3" header="Quotation">
        <b-table-simple hover small caption-top responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="text-left">Description</b-th>
              <b-th class="text-right">Amount</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in quote" :key="item.id">
              <b-th class="text-left">{{item.tlu_type}}</b-th>
              <b-td class="text-right">{{item.premium.toLocaleString()}}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td colspan="7" variant="secondary" class="text-right">
                Total Premium: <b>{{quote.reduce((prev,cur)=>{return prev+cur.premium},0).toLocaleString()}}</b>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { db } from "@/store/db";

export default {
  data() {
    return {
      form: { area_id: null, season_id: null, tlu_type_id: [], quantity: [] },
      seasons: [],
      areas: [],
      tlutypes: [],
      tlutypeOptions: [],
      quote: [],
    };
  },
  methods: {
    async calculatePricePerTLU(tlu_type_id,index) {
      if(tlu_type_id==undefined) return;

      let price = await db.pricing
        .where(["tlu_type_id", "area_id", "season_id"])
        .equals([
          parseInt(tlu_type_id),
          parseInt(this.form.area_id),
          parseInt(this.form.season_id),
        ])
        .first();

      console.info(`Quote index: ${index} tlu_type_id: ${tlu_type_id} area_id:${this.form.area_id} season_id:${this.form.season_id}`);

      let tlutype = this.tlutypes.filter((item)=>{return item.tlu_type_id==tlu_type_id})[0];

      if(price==null || tlutype==null){
        this.$bvToast.toast("Pricing missing, please re-sync.", {
              duration: 5000,
              position: "top-center",
              title: "Quote",
              variant: "warning",
            });
        return;
      }

      this.quote.push({
        id: tlu_type_id,
        tlu: tlutype.tlu,
        tlu_type:tlutype.tlu_type,
        sum_insured:
          price.tlu_type_sum_insured *
          parseInt(this.form.quantity[index]),
        premium:
          price.tlu_type_premium * parseInt(this.form.quantity[index]),
      });
    },
    async onSubmit(event) {
      event.preventDefault();

      this.quote = [];

      let index=0;
      this.form.tlu_type_id.forEach((element) =>
        this.calculatePricePerTLU(element,++index)
      );

    },
    onReset(event) {
      event.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async loadSeasons() {
      let data = await db.seasons.toArray();

      this.seasons.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.seasons.push({
          value: element.season_id,
          text: element.season,
        });
      });
    },
    async loadAreas() {
      let data = await db.areas.toArray();

      this.areas.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.areas.push({
          value: element.area_id,
          text: element.area_name,
        });
      });
    },
    async loadTLUTypes() {
      this.tlutypes = await db.tlutypes.toArray();

      this.tlutypeOptions.push({ value: null, text: "-select one-" });

      if (this.tlutypes == null) return;

      this.tlutypes.forEach((element) => {
        this.tlutypeOptions.push({
          value: element.tlu_type_id,
          text: element.tlu_type,
        });
      });
    },
  },
  created() {
    this.loadSeasons();
    this.loadAreas();
    this.loadTLUTypes();
  },
};
</script>