import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

import { loadAgent } from "./Agents";

async function saveHerder(item, resource) {
  let data = await db.herders.where("id").equals(parseInt(item.id)).first();

  if (data !== null && resource !== null && !(resource instanceof Array)) {
    await db.herders.put({
      ...item,
      ...resource,
      date_synced: new Date().getTime()
    });
  }
}

async function addHerder(tokenStr, token, agent, item) {
  console.info("New Herder, upload");

  //update fields
  item.herder_id = null;
  item.agent_id = agent.agent_id;
  item.organisation_id = parseInt(token.organisation_id);

  //save
  axios
    .create({
      baseURL: window.ibliURL,
      headers: { Authorization: `Bearer ${tokenStr}` },
    })
    .post("/api/v1/herders", item)
    .then((response) => {
      let resource = response.data.resource;

      try {
        saveHerder(item, resource);

        console.info("syncHerder:Herder synced....");
      } catch (error) {
        console.info("syncHerder:saveHerder issue: " + error);
      }

      console.info("syncHerder:Herder Saved");
    })
    .catch((e) => {
      console.info("syncHerder:Herder not Saved");

      var errors = e.response.data.errors;
      if (errors instanceof Array) {
        errors.forEach((element) => {
          console.error(
            "syncHerder:Herder Upload Error: " + element.message
          );
        });
      }
    });
}

async function updateHerder(tokenStr, token, item, response) {
  console.info(
    "syncHerder:Existing Herder, push update if changed before, otherwise ignore...."
  );
  let resource = response.data.results[0];

  if (
    resource.record_version <= item.record_version ||
    item.record_version == null
  ) {
    //update fields
    item.herder_id = resource.herder_id;
    item.organisation_id = parseInt(token.organisation_id);
    item.record_version = resource.record_version;

    //save
    axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .put("/api/v1/herders/" + resource.herder_id, item)
      .then((response) => {
        let resource = response.data.resource;

        try {
          saveHerder(item, resource);

          console.info("syncHerder:Herder synced....");
        } catch (error) {
          console.info("syncHerder:saveHerder issue: " + error);
        }

        console.info("syncHerder:Herder Saved");
      })
      .catch((e) => {
        console.info("syncHerder:Herder not Saved");

        var errors = e.response.data.errors;
        if (errors instanceof Array) {
          errors.forEach((element) => {
            console.error(
              "syncHerder:Herder Upload Error: " + element.message
            );
          });
        }
      });
  }
}

async function syncHerder(tokenStr, token, item) {

  let agent = await loadAgent();

  if (agent != null && agent.agent_id>0) {

    await axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .get(`/api/v1/herders?phone_number=${item.phone_number}`)
      .then((response) => {
        if (response.data == null || response.data.results == null) {
          addHerder(tokenStr, token, agent, item);
        } else {
          updateHerder(tokenStr, token, item, response);
        }
      })
      .catch((e) => {
        console.info(
          "syncHerder:Herder syncs did not succeed. " +
          e.toString() +
          " failed"
        );
      });
  } else {
    // console.info("syncHerder: Agent NOT loaded.");
    debounceLeading(syncHerder(tokenStr, token, item), 5000);
  }
}
async function uploadHerders(tokenStr, token) {
  let agent = await loadAgent();

  if (agent != null && agent.agent_id>0) {
    let data = await db.herders.toArray();

    data.forEach((item) => {
      console.info(`uploadHerders:Herder: ${item.id}`);
      syncHerder(tokenStr, token, item);
    });
  } else {
    // console.info("uploadHerders: Agent NOT loaded.");
    debounceLeading(uploadHerders(tokenStr, token), 5000);
  }
}

export { uploadHerders };