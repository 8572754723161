import axios from "axios";

import { db } from "@/store/db";

async function saveOrganisation(item) {
    let data = await db.organisations
      .where("organisation_id")
      .equals(parseInt(item.organisation_id))
      .first();

    if (data !== null) {
      db.organisations.put({
        ...item,
        date_synced: new Date().getTime()
      });
    } else {
      db.organisations.add({
        ...item,
        date_synced: new Date().getTime()
      });
    }
  }

  async function pullOrganisations(tokenStr) {
    await axios
      .create({
        baseURL: window.authURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .get(
        "/api/v1/organisations?all=true&columns=organisation_id,organisation_name"
      )
      .then((response) => {
        if (response.data == null || response.data.results == null) {
          console.info("pullOrganisations:Missing Data");
          return;
        } else {
          console.info("pullOrganisations:Syncing Areas....");
        }

        try {
          response.data.results.forEach((item) => {
            saveOrganisation(item);
          });

          console.info("pullOrganisations:Organisation synced....");
        } catch (error) {
          console.info("pullOrganisations:saveOrganisation issue: " + error);
        }
      })
      .catch((e) => {
        console.info(
          "pullOrganisations:Organisation syncs did not succeed. " +
            e.toString() +
            " failed"
        );
      });
  }

  export {pullOrganisations};