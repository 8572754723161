<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> TLU </b>
    </template>

    <b-card :title="'Area: '">
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-area_id"
              label="Area:"
              label-for="input-area_id"
            >
              <b-form-select
                id="input-area_id"
                class="border-input"
                v-model="form.area_id"
                :options="areas"
                placeholder="Enter Area ID"
              ></b-form-select>
            </b-form-group>
          </b-col>
          
          <b-col>
            <b-form-group
              id="input-group-tlu_status"
              label="Status:"
              label-for="input-tlu_status"
            >
              <b-form-select
                id="input-tlu_status"
                class="border-input"
                v-model="form.tlu_status"
                :options="statuses"
                required
                placeholder="Enter Status"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-button type="submit" variant="primary"
          >Submit</b-button
        >&nbsp;
        <b-button type="reset" variant="danger"
          >Reset</b-button
        >
      </b-form>
    </b-card>
  </b-card>
</template>

<script>

import { db } from "@/store/db";

export default {
  name: "tlu",
  props: ["herder_id", "id"],
  data() {
    return {
      form: {
        herder_id: null,
        organisation_id: null,
        area_id: null,
        units:0,
        tlu_status: null,
        record_version: null,
      },
      areas: [],
      response: null,
      error: null,
      statuses: [
        { value: null, text: "-select one-" },
        { value: "ACTIVE", text: "ACTIVE" },
        { value: "IN-ACTIVE", text: "IN-ACTIVE" },
      ],
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();

      let toast = this.$bvToast;
      let router=this.$router;
      let that = this;

      let area = await db.areas
          .where("area_id")
          .equals(parseInt(this.form.area_id))
          .first();

      this.form.area_name = area.parent_area_name + " " + area.area_name;

      if (this.id != null || this.id != undefined) {
        this.form.id = parseInt(this.id);

        //update
        db.tlus
          .put(this.form)
          .then(function () {
            toast.toast("Saved.", {
              duration: 5000,
              position: "top-center",
              title: "TLU",
              variant: "success",
            });

            router.push(`/tlus/${that.form.herder_x_id}`);
          })
          .catch(function () {
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "TLU",
              variant: "error",
            });
          });
      } else {

        this.form.herder_x_id = parseInt(this.herder_id);

        let tlu = await db.tlus
          .where("[herder_x_id+area_id]")
          .equals([this.form.herder_x_id,this.form.area_id])
          .first();

        if (tlu != null) {
          toast.toast("Save Failed. Area already exists.", {
            duration: 5000,
            position: "top-center",
            title: "TLU",
            variant: "error",
          });
          return;
        }

        //add
        db.tlus
          .add(this.form)
          .then(function () {
            toast.toast("Saved.", {
              duration: 5000,
              position: "top-center",
              title: "TLU",
              variant: "success",
            });

            router.push(`/tlus/${that.herder_id}`);
          })
          .catch(function (e) {
            console.info(`TLU Save Failed: ${e.toString()}`);
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "TLU",
              variant: "error",
            });
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();

      this.fetchData();

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async fetchData() {
      if (this.id != null || this.id != undefined) {
        let data = await db.tlus
          .where("id")
          .equals(parseInt(this.id))
          .first();
        if (data != null) {
          this.form = data;
        }
      }
    },
    async loadAreas() {
      let data = await db.areas.toArray();

      this.areas.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.areas.push({
          value: element.area_id,
          text: element.parent_area_name + " " + element.area_name,
        });
      });
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
    this.loadAreas();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
};
</script>