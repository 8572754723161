import axios from "axios";

import { db } from "@/store/db";

async function saveProgramArea(item) {
    let data = await db.program_areas
      .where("program_area_id")
      .equals(parseInt(item.program_area_id))
      .first();

    if (data !== null) {
      db.program_areas.put({
        ...item,
        date_synced: new Date().getTime()
      });
    } else {
      db.program_areas.add({
        ...item,
        date_synced: new Date().getTime()
      });
    }
  }
  
  async function pullProgramAreas(tokenStr,program_id) {
    axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .get(
        "/api/v1/program_areas?all=true&program_area_status=ACTIVE&program_id=" +
          program_id
      )
      .then((response) => {
        if (response.data == null || response.data.results == null) {
          console.info("pullProgramAreas:Missing Data");
          return;
        } else {
          console.info("pullProgramAreas:Syncing Program Areas ....");
        }

        try {
          response.data.results.forEach((item) => {
            saveProgramArea(item);
          });

          console.info("pullProgramAreas:Program Area synced....");
        } catch (error) {
          console.info("pullProgramAreas:saveProgramArea issue: " + error);
        }
      })
      .catch((e) => {
        console.info(
          "pullProgramAreas:Program Area syncs did not succeed. " +
            e.toString() +
            " failed"
        );
      });
  }

  export {pullProgramAreas};