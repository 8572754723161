import axios from "axios";

import {debounceLeading} from "./Debounce";

import { db } from "@/store/db";

import { loadAgent } from "./Agents";

import {pullProgramAreas} from "./ProgramAreas";

import {pullProgramFinanciers} from "./ProgramFinanciers";

async function saveAgentProgram(item) {
    let data = await db.agent_programs
      .where("agent_program_id")
      .equals(parseInt(item.agent_program_id))
      .first();

    if (data !== null) {
      db.agent_programs.put({
        ...item,
        date_synced: new Date().getTime()
      });
    } else {
      db.agent_programs.add({
        ...item,
        date_synced: new Date().getTime()
      });
    }
  }

  async function pullAgentPrograms(tokenStr) {
    let agent = await loadAgent();

    if (agent != null && agent.agent_id>0) {
      await axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .get(
          "/api/v1/agent_programs?all=true&agent_program_status=ACTIVE&agent_id=" +
            agent.agent_id
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("pullAgentPrograms:Missing Data");
            return;
          } else {
            console.info("pullAgentPrograms:Syncing Agent Programs ....");
          }

          try {
            response.data.results.forEach((item) => {
              saveAgentProgram(item);
              //pull the areas
              pullProgramAreas(tokenStr,item.program_id);
              //pull the financiers
              pullProgramFinanciers(tokenStr,item.program_id);
            });

            console.info("pullAgentPrograms:Agent Program synced....");
          } catch (error) {
            console.info(
              "pullAgentPrograms:saveAgentProgram issue: " + error
            );
          }
        })
        .catch((e) => {
          console.info(
            "pullAgentPrograms:Agent Program syncs did not succeed. " +
              e.toString() +
              " failed"
          );
        });
    } else {
      // console.info(
      //   "pullAgentPrograms:Agent Programs NOT synced, Agent NOT loaded."
      // );
      debounceLeading(pullAgentPrograms(tokenStr), 15000);
    }
  }

  export {pullAgentPrograms};