<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> TLU </b>
    </template>

    <b-card title="TLU Composition">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-tlu_type_id"
              label="Type:"
              label-for="input-tlu_type_id"
            >
              <b-form-select
                id="input-tlu_type_id"
                class="border-input"
                v-model="form.tlu_type_id"
                :options="tluTypes"
                required
                placeholder="Enter Type"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-quantity"
              label="Quantity:"
              label-for="input-quantity"
            >
              <b-form-input
                id="input-quantity"
                class="border-input"
                type="text"
                v-model="form.quantity"
                required
                placeholder="Quantity"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-tlu_composition_status"
              label="Status:"
              label-for="input-tlu_composition_status"
            >
              <b-form-select
                id="input-tlu_composition_status"
                class="border-input"
                v-model="form.tlu_composition_status"
                :options="statuses"
                required
                placeholder="Enter Status"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col> </b-col>
        </b-form-row>

        <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

export default {
  name: "tlucomposition",
  props: ["tlu_id", "id"],
  data() {
    return {
      form: {
        tlu_composition_id: null,
        organisation_id: null,
        herder_id: null,
        quantity: null,
        tlu_id: null,
        tlu_type_id: null,
        tlu_composition_status: null,
        record_version: null,
      },
      show: true,
      loading: false,
      response: null,
      error: null,
      statuses: [
        { value: null, text: "-select one-" },
        { value: "ACTIVE", text: "ACTIVE" },
        { value: "IN-ACTIVE", text: "IN-ACTIVE" },
      ],
      tluTypes: [],
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();

      let toast = this.$bvToast;
      let router = this.$router;

      let tlutype = await db.tlutypes
        .where("tlu_type_id")
        .equals(parseInt(this.form.tlu_type_id))
        .first();

      this.form.tlu_type = tlutype.tlu_type;
      this.form.units = tlutype.tlu * this.form.quantity;

      //verify we do not duplicate entries
      if (this.tlu_id != null || this.tlu_id != undefined) {
        let existingComposition = await db.tlucompositions
          .where({
            tlu_x_id: parseInt(this.tlu_id),
            tlu_type: this.form.tlu_type,
          })
          .first();
        if (existingComposition != null) {
          toast.toast("Save Failed. Type already exists, edit existing one", {
            duration: 5000,
            position: "top-center",
            title: "TLU Composition",
            variant: "error",
          });
          return;
        }
      }

      if (this.id != null || this.id != undefined) {
        this.form.id = parseInt(this.id);

        //update
        await db.tlucompositions
          .put(this.form)
          .then(function () {
            toast.toast("Saved.", {
              duration: 5000,
              position: "top-center",
              title: "TLU Composition",
              variant: "success",
            });
          })
          .catch(function () {
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "TLU Composition",
              variant: "error",
            });
          });
      } else {
        this.form.tlu_x_id = parseInt(this.tlu_id);

        //add
        await db.tlucompositions
          .add(this.form)
          .then(function () {
            toast.toast("Saved.", {
              duration: 5000,
              position: "top-center",
              title: "TLU Composition",
              variant: "success",
            });
          })
          .catch(function () {
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "TLU Composition",
              variant: "error",
            });
          });
      }
      //update the composition
      let compositions = await db.tlucompositions
        .where("tlu_x_id")
        .equals(parseInt(this.form.tlu_x_id))
        .toArray();

      let units = compositions.reduce(function (result, item) {
        return result + item.units;
      }, 0);

      let tlu = await db.tlus
        .where("id")
        .equals(parseInt(this.form.tlu_x_id))
        .first();

      if (tlu != null) {
        tlu.units = units;

        db.tlus
          .put(tlu)
          .then(function () {
            console.info("TLU Saved");
            router.push(`/tlus/${tlu.herder_x_id}`);
          })
          .catch(function (e) {
            console.info("TLU NOT Saved" + e);
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();

      // Reset our form values
      this.fetchData();

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async fetchData() {
      if (this.id != null || this.id != undefined) {
        let data = await db.tlucompositions
          .where("id")
          .equals(parseInt(this.id))
          .first();
        if (data != null) {
          this.form = data;
        }
      }
    },
    async loadTypes() {
      let data = await db.tlutypes.toArray();

      this.tluTypes.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.tluTypes.push({
          value: element.tlu_type_id,
          text: element.tlu_type,
        });
      });
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
    this.loadTypes();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
};
</script>