<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> Herders </b>
    </template>
    <router-link class="btn mr-1 btn-primary btn-sm" to="/herder">
      New Herder
    </router-link>
    <b-card>
      <b-table striped hover responsive :items="items" :fields="fields">
        <template v-slot:cell(herder_name)="row">
          {{ row.item.first_name }} {{ row.item.second_name }}
        </template>

        <template v-slot:cell(actions)="row">
          <b-dropdown right text="---">
            <b-dropdown-item>
              <router-link
                :to="getEditPath(row.item)"
                title="Edit"
              >
                Edit</router-link
              >
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <router-link
                :to="getBeneficiaryPath(row.item)"
                title="Beneficiary"
              >
                Beneficiary</router-link
              >
            </b-dropdown-item> -->
            <b-dropdown-item>
              <router-link
                :to="getTLUsPath(row.item)"
                title="TLUs"
              >
                TLUs</router-link
              >
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <router-link
                :to="getAssignToGroupPath(row.item)"
                title="Group"
              >
                Group</router-link
              >
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

export default {
  data() {
    return {
      fields: [
        { key: "herder_name", sortable: true },
        { key: "phone_number", sortable: true },
        { key: "actions" },
      ],
      items: null,
    };
  },
  methods: {
    loadTLUs: async function () {
      this.items = await db.herders.toArray();
    },
    getEditPath(item) {
      return "/herder/" + item.id;
    },
    getBeneficiaryPath(item) {
      return "/beneficiary/" + item.id;
    },
    getTLUsPath(item) {
      return "/tlus/" + item.id;
    },
    getNewPolicyPath(item) {
      return "/new_policy/herder/" + item.id;
    },
    getAssignToGroupPath(item) {
      return "/herder/group/" + item.id;
    },
  },
  created() {
    this.loadTLUs();
  },
};
</script>