<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> TLUs </b>
    </template>
    <router-link class="btn mr-1 btn-primary btn-sm" :to="'/tlu/add/' + this.id">
      New Area
    </router-link>
    <b-card>
      <b-table striped hover :items="items" :fields="fields">
        <template v-slot:cell(actions)="row">
          <router-link
                :to="getEditPath(row.item)"
                title="Composition"
              >
                Composition</router-link
              >
        </template>
      </b-table>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

export default {
  props:["id"],
  data() {
    return {
      fields: [
        { key: "area_name", sortable: true },
        { key: "units", sortable: true },
        { key: "actions" },
      ],
      items: null,
    };
  },
  methods: {
    loadTLUs: async function () {
      this.items = await db.tlus.where("herder_x_id").equals(parseInt(this.id)).toArray();
    },
    getEditPath(item) {
      return "/tlu/compositions/" + item.id;
    },
  },
  created() {
    this.loadTLUs();
  },
};
</script>