import axios from "axios";

import { db } from "@/store/db";

async function savePricing(item) {
    let data = await db.pricing
      .where("pricing_tlu_type_id")
      .equals(parseInt(item.pricing_tlu_type_id))
      .first();

    if (data !== null) {
      db.pricing.put({
        ...item,
        date_synced: new Date().getTime()
      });
    } else {
      db.pricing.add({
        ...item,
        date_synced: new Date().getTime()
      });
    }
  }
  async function pullPricings(tokenStr,season) {
    axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .get(
        "/api/v1/data/vw_pricing_tlu_types?all=true&id=pricing_tlu_type_id" +
          "&iCols=pricing_tlu_type_id,pricing_id,tlu_type_id,tlu_type_premium,tlu_type_sum_insured,area_id,season_id" +
          "&sCols=season&columns=pricing_tlu_type_id,pricing_id,tlu_type_id,tlu_type_premium,tlu_type_sum_insured,area_id,season_id,season" +
          "&season_id=" +
          season.season_id
      )
      .then((response) => {
        if (response.data == null || response.data.results == null) {
          console.info("pullPricings:Missing Data");
          return;
        } else {
          console.info("pullPricings:Syncing Pricings....");
        }

        try {
          response.data.results.forEach((item) => {
            savePricing(item);
          });

          console.info("pullPricings:Pricings synced....");
        } catch (error) {
          console.info("pullPricings:savePricing issue: " + error);
        }
      })
      .catch((e) => {
        console.info(
          "pullPricings:Pricing syncs did not succeed. " +
            e.toString() +
            " failed"
        );
      });
  }

  export {pullPricings};