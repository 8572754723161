import Dexie from 'dexie';

export const db = new Dexie('ibli');

db.version(21).stores({
  status:'++id,date_synced,date_modified',
  tlutypes:'tlu_type_id,tlu_type,tlu,date_synced,date_modified',
  agents:'agent_id,date_synced,date_modified',
  areas:'agent_area_id,area_id,area_name,area_type,date_synced,date_modified',
  herders:'id++,herder_id,herder_number,first_name,second_name,&phone_number,id_type,id_number,date_synced,date_modified',
  beneficiaries:'id++,beneficiary_id,&herder_x_id,date_synced,date_modified',
  groups:'id++,group_id,&group_number,&group_name,phone_number,group_type,date_synced,date_modified',
  groupherders:'id++,group_x_id,herder_x_id,date_synced,date_modified',
  tlus:'id++,&[herder_x_id+area_id],tlu_x_id,tlu_id,herder_id,date_synced,date_modified',
  tlucompositions:'id++,[tlu_x_id+tlu_type],[tlu_x_id+tlu_composition_id],tlu_x_id,herder_x_id,tlu_composition_x_id,tlu_composition_id,tlu_type,date_synced,date_modified',
  seasons:'season_id,season,season_status,date_synced,date_modified',
  pricing:'pricing_tlu_type_id,pricing_id,[tlu_type_id+area_id+season_id],tlu_type_premium,tlu_type_sum_insured,season,date_synced,date_modified',
  quotations:'id++,quotation_id,&[herder_x_id+group_x_id+season_id+area_id],[herder_x_id+season_id],program_id,tlus,sum_insured,quotation_amount,date_synced,date_modified',
  organisations:'organisation_id,organisation_name',
  agent_programs:'agent_program_id,agent_id,program_id,program_name',
  program_areas:'program_area_id,program_id,area_id,parent_area_name,area_name',
  program_financiers:'program_financier_id,program_id,financier_id,financier,contribution_type,contribution'
});