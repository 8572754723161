import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

import { loadAgent } from "./Agents";

import { syncTLUCompositions } from "./TLUCompositions";

async function saveTLU(tokenStr, token, item, resource) {
    let data = await db.tlus.where("id").equals(parseInt(item.id)).first();

    if (data !== null && resource !== null && !(resource instanceof Array)) {
        await db.tlus.put({
            ...item,
            ...resource,
            date_synced: new Date().getTime()
        });

        //upload TLU compositions
        await syncTLUCompositions(tokenStr, token, item);
    }
}

async function addTLU(tokenStr, token, herder, item) {
    console.info("syncTLU:New TLU, upload");

    //update fields
    item.tlu_id = null;
    item.organisation_id = parseInt(token.organisation_id);
    item.herder_id = parseInt(herder.herder_id);

    //save
    await axios
        .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .post("/api/v1/tlus", item)
        .then((response) => {
            let resource = response.data.resource;

            try {
                saveTLU(tokenStr, token, item, resource);

                console.info("syncTLU:TLU synced....");
            } catch (error) {
                console.info("syncTLU:saveTLU issue: " + error);
            }

            console.info("syncTLU:TLU Saved");
        })
        .catch((e) => {
            console.info("syncTLU:TLU not Saved");

            var errors = e.response.data.errors;
            if (errors instanceof Array) {
                errors.forEach((element) => {
                    console.error(
                        "syncTLU:TLU Upload Error: " +
                        element.message.toString()
                    );
                });
            }
        });
}

async function updateTLU(tokenStr, token, item, response) {
    console.info(
        "syncTLU:Existing TLU, push update if changed before, otherwise ignore...."
    );
    let resource = response.data.results[0];

    if (
        resource.record_version <= item.record_version ||
        item.record_version == null
    ) {
        //update fields
        item.tlu_id = resource.tlu_id;
        item.herder_id = resource.herder_id;
        item.organisation_id = parseInt(token.organisation_id);
        item.record_version = resource.record_version;

        //save
        await axios
            .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .put("/api/v1/tlus/" + resource.tlu_id, item)
            .then((response) => {
                resource = response.data.resource;

                try {
                    saveTLU(tokenStr, token, item, resource);

                    console.info("syncTLU:TLU synced....");
                } catch (error) {
                    console.info("syncTLU:saveTLU issue: " + error);
                }

                console.info("syncTLU:TLU Saved");
            })
            .catch((e) => {
                console.info("syncTLU:TLU not Saved");

                var errors = e.response.data.errors;
                if (errors instanceof Array) {
                    errors.forEach((element) => {
                        console.error(
                            "syncTLU:TLU Upload Error: " +
                            element.message.toString()
                        );
                    });
                }
            });
    }
}

async function syncTLU(tokenStr, token, item) {
    console.info(`Sync TLU: ${item.id}`);

    let herder = await db.herders
        .where("id")
        .equals(parseInt(item.herder_x_id))
        .first();


    if (herder.herder_id !== null) {
        await axios
            .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .get(
                `/api/v1/tlus?herder_id=${herder.herder_id}&area_id=${item.area_id}`
            )
            .then((response) => {
                if (response.data == null || response.data.results == null) {
                    addTLU(tokenStr, token, herder, item)
                } else {
                    updateTLU(tokenStr, token, item, response)
                }
            })
            .catch((e) => {
                console.info(
                    "syncTLU:TLU syncs did not succeed. " + e.toString() + " failed"
                );
            });
    } else {
        // console.info(":syncTLU:TLU not synced, do sync after herder synced");
        debounceLeading(syncTLU(tokenStr, token, item), 5000);
    }
}

async function syncTLUs(tokenStr, token) {
    let agent = await loadAgent();

    if (agent != null && agent.agent_id>0) {
        let data = await db.tlus.toArray();

        data.forEach((item) => {
            console.info(
                `Sync Herder TLU: ${item.herder_x_id} Area: ${item.area_name}`
            );

            syncTLU(tokenStr, token, item);
        });
    } else {
        // console.info("syncTLUs: Agent NOT loaded.");
        debounceLeading(syncTLUs(tokenStr, token), 5000);
    }
}

export { syncTLUs };