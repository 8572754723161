import axios from "axios";

import { db } from "@/store/db";

import {pullPricings} from "./Pricings";

async function saveSeason(tokenStr,item) {
    let data = await db.seasons
        .where("season_id")
        .equals(parseInt(item.season_id))
        .first();

    if (data !== null) {
        db.seasons.put({
            ...item,
            date_synced: new Date().getTime()
        });
    } else {
        db.seasons.add({
            ...item,
            date_synced: new Date().getTime()
        });
    }
    //get pricings
    pullPricings(tokenStr,item);
}
async function pullSeasons(tokenStr) {
    await axios
        .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .get("/api/v1/seasons")
        .then((response) => {
            if (response.data == null || response.data.results == null) {
                console.info("pullSeasons:Missing Data");
                return;
            } else {
                console.info("pullSeasons:Syncing Seasons....");
            }

            try {
                response.data.results.forEach((item) => {
                    saveSeason(tokenStr,item);
                });

                console.info("pullSeasons:Seasons synced....");
            } catch (error) {
                console.info("pullSeasons:saveSeason issue: " + error);
            }
        })
        .catch((e) => {
            console.info(
                "pullSeasons:Season syncs did not succeed. " +
                e.toString() +
                " failed"
            );
        });
}

export { pullSeasons };