<template>
  <b-container fluid="sm">
    <p />
    <b-card
      title="Synchronise"
      img-src="img/icons/apple-touch-icon-152x152.png"
      img-alt="Synchronise"
      img-top
      tag="article"
      style="max-width: 20rem"
      class="mb-2"
    >
      <div>
        <b-form-group
          id="input-group-username"
          label="Username:"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="form.username"
            placeholder="Enter Username"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-password"
          label="Password:"
          label-for="input-password"
        >
          <b-form-input
            id="input-password"
            type="password"
            v-model="form.password"
            placeholder="Enter Password"
            required
          ></b-form-input>
        </b-form-group>

        <b-overlay
          :show="busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="onHidden"
        >
          <b-button
            ref="button"
            :disabled="busy"
            variant="primary"
            @click="onInitiate"
          >
            Initiate
          </b-button>
        </b-overlay>
      </div>
      <template #footer>
        <small class="text-muted"></small>

        <small class="text-muted" v-if="sync">
          Last synchronisation at {{ sync.synctime }}
        </small>

        <small v-if="!sync"> Never synchronised, synchronise now. </small>
      </template>
    </b-card>
  </b-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";

import { db } from "@/store/db";

import { pullTLUTypes } from "./TLUTypes";

import { pullAgents } from "./Agents";

import { pullAreas } from "./Areas";

import {pullSeasons} from "./Seasons";

import {pullOrganisations} from "./Organisations";

import {pullAgentPrograms} from "./AgentPrograms";

import {uploadHerders} from "./Herders";

import {uploadBeneficiaries} from "./Beneficiaries";

import {uploadGroups} from "./Groups";

import {syncTLUs} from "./TLUs";

import {uploadHerderGroups} from "./HerderGroups";

import {uploadQuotations} from "./Quotations";

export default {
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      token: null,
      tokenStr: null,
      agent: null,
      busy: false,
      timeout: null,
      sync: null
    };
  },
  methods: {
    async loadStatus() {
      let data = await db.status.toArray();
      this.sync = data[0] || null;
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    parseJwt(token) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      return JSON.parse(payload.toString());
    },
    
    async doSync() {
      //get tlu types
      await pullTLUTypes(this.tokenStr, this.token);

      //get agents
      await pullAgents(this.tokenStr, this.token);

      //get areas
      await pullAreas(this.tokenStr);

      //get seasons
      await pullSeasons(this.tokenStr);

      //get organisations
      await pullOrganisations(this.tokenStr);

      //get agent programs
      await pullAgentPrograms(this.tokenStr);

      //upload herders
      await uploadHerders(this.tokenStr, this.token);

      //upload beneficiaries
      await uploadBeneficiaries(this.tokenStr, this.token);

      //upload groups
      await uploadGroups(this.tokenStr, this.token);

      //upload herder groups
      await uploadHerderGroups(this.tokenStr, this.token);

      //upload TLUs
      await syncTLUs(this.tokenStr, this.token);

      //upload quotations
      await uploadQuotations(this.tokenStr, this.token);

      //update
      if (this.sync) {
        db.status.put({
          id: this.sync.id,
          synctime: new Date(),
          status: "COMPLETED"
        });
      } else {
        db.status.add({
          synctime: new Date(),
          status: "COMPLETED"
        });
      }

      this.$bvToast.toast("Synchronisation completed", {
        target: "b-toaster-top-center",
        duration: 5000,
        position: "top-center",
        title: "Synchronisation",
        variant: "success"
      });

      this.loadStatus();

      this.busy = false;
    },
    async onInitiate() {
      this.busy = true;

      const params = new URLSearchParams();
      params.append("consumer_key", this.form.username);
      params.append("consumer_secret", this.form.password);

      //try access a resource
      await axios
        .create({
          baseURL: window.authURL,
        })
        .post("/oauth/token", params)
        .then((response) => {
          this.response = response.data;

          this.tokenStr = this.response.token;
          this.token = this.parseJwt(this.tokenStr);

          this.doSync();
        })
        .catch(() => {
          this.busy = false;

          this.$bvToast.toast(
            "Login Failed. Please check your credentials and try again",
            {
              duration: 5000,
              position: "top-center",
              title: "Synchronisation",
              variant: "error"
            }
          );
        });
    },
  },
  async created() {
    await this.loadStatus();
  },
};
</script>