import axios from "axios";

import { db } from "@/store/db";

async function loadAgent() {
    let data = await db.agents.toArray();
    return data[0] || null;
}

async function saveAgent(item) {
    let data = await db.agents
        .where("agent_id")
        .equals(parseInt(item.agent_id))
        .first();

    if (data !== null) {
        db.agents.put({
            ...item,
            date_synced: new Date().getTime()
        });
    } else {
        db.agents.add({
            ...item,
            date_synced: new Date().getTime()
        });
    }
}
async function pullAgents(tokenStr, token) {
    await axios
        .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .get("/api/v1/agents?agent_status=ACTIVE&user_id=" + token.user_id)
        .then((response) => {
            if (response.data == null || response.data.results == null) {
                console.info("pullAgents:Missing Data");
                return;
            } else {
                console.info("Syncing Agents....");
            }

            try {
                response.data.results.forEach((item) => {
                    saveAgent(item);
                });

                console.info("pullAgents:Agents synced....");
            } catch (error) {
                console.info("pullAgents:saveAgent issue: " + error);
            }
        })
        .catch((e) => {
            console.info(
                "pullAgents:Agent sync did not succeed. " + e.toString() + " failed"
            );
        });
}

export { loadAgent, pullAgents }