
import axios from "axios";

import { db } from "@/store/db";

import {debounceLeading} from "./Debounce";

import { loadAgent } from "./Agents";

async function saveQuotation(item, resource) {
  let data = await db.quotations
    .where("id")
    .equals(parseInt(item.id))
    .first();

  if (data !== null && resource !== null && !(resource instanceof Array)) {
    db.quotations.put({
      ...item,
      ...resource,
      date_synced: new Date().getTime()
    });
  }
}

async function addQuotation(tokenStr, token, herder, group, item) {
  console.info("syncQuotation:New Quotation, upload");

  //update fields
  item.quotation_id = null;
  item.herder_id = herder.herder_id;
  item.group_id = group.group_id;
  item.organisation_id = parseInt(token.organisation_id);

  //save
  await axios
    .create({
      baseURL: window.ibliURL,
      headers: { Authorization: `Bearer ${tokenStr}` },
    })
    .post("/api/v1/quotations", item)
    .then((response) => {
      let resource = response.data.resource;

      try {
        saveQuotation(item, resource);

        console.info("syncQuotation:Quotation synced....");
      } catch (error) {
        console.info("syncQuotation:saveQuotation issue: " + error);
      }

      console.info("syncQuotation:Quotation Saved");
    })
    .catch((e) => {
      console.info("syncQuotation:Quotation not Saved");

      var errors = e.response.data.errors;
      if (errors instanceof Array) {
        errors.forEach((element) => {
          console.error(
            "syncQuotation:Quotation Upload Error: " +
            element.message
          );
        });
      }
    });
}

async function updateQuotation(tokenStr, item, response) {
  console.info(
    "syncQuotation:Existing Quotation, push update if changed before, otherwise ignore...."
  );
  let resource = response.data.results[0];

  if (resource.record_version <= item.record_version) {
    //save
    await axios
      .create({
        baseURL: window.ibliURL,
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .put("/api/v1/quotations/" + resource.quotation_id, item)
      .then((response) => {
        resource = response.data.resource;

        try {
          saveQuotation(item, resource);

          console.info("syncQuotation:Quotation synced....");
        } catch (error) {
          console.info(
            "syncQuotation:saveQuotation issue: " + error
          );
        }

        console.info("syncQuotation:Quotation Saved");
      })
      .catch((e) => {
        console.info("syncQuotation:Quotation not Saved");

        var errors = e.response.data.errors;
        if (errors instanceof Array) {
          errors.forEach((element) => {
            console.error(
              "syncQuotation:Quotation Upload Error: " +
              element.message
            );
          });
        }
      });
  }
}

async function syncQuotation(tokenStr, token, item) {

  if (item.herder_x_id != null || item.group_x_id != null) {

    let herder = await db.herders
      .where("id")
      .equals(item.herder_x_id != null ? parseInt(item.herder_x_id) : -1)
      .first()
      .catch(() => {
        return { herder_id: null };
      });


    let group = await db.groups
      .where("id")
      .equals(item.group_x_id != null ? parseInt(item.group_x_id) : -1)
      .first()
      .catch(() => {
        return { group_id: null };
      });

    /*verify tlu compositions have been uploaded*/
    let tlu = await db.tlus
      .where({ herder_x_id: item.herder_x_id, area_id: item.area_id })
      .first();

    let tluCompositions = await db.tlucompositions
      .where({tlu_x_id:tlu.id})
      .toArray();

    let unSyncedCompositions = tluCompositions.filter(item=>item.tlu_composition_id==null);

    /*set defaults incase of failure*/
    herder = herder || { herder_id: null };
    group = group || { group_id: null }

    if ((herder.herder_id != null || group.group_id != null) && unSyncedCompositions.length == 0) {
      await axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .get(
          `/api/v1/quotations?group_id=${group.group_id}&herder_id=${herder.herder_id}&area_id=${item.area_id}&season_id=${item.season_id}`
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            addQuotation(tokenStr, token, herder, group, item);
          } else {
            updateQuotation(tokenStr, item, response)
          }
        })
        .catch((e) => {
          console.info(
            "syncQuotation:Quotation syncs did not succeed. " +
            e.toString() +
            " failed"
          );
        });
    } else {
      // console.info(
      //   "syncQuotation:Quotation not synced, do sync after herder|group synced"
      // );
      debounceLeading(syncQuotation(tokenStr, token, item), 5000);
    }
  } else {
    // console.info(
    //   "syncQuotation:Quotation not synced, do sync after herder|group synced"
    // );
    debounceLeading(syncQuotation(tokenStr, token, item), 5000);
  }
}

async function uploadQuotations(tokenStr, token) {
  let agent = await loadAgent();

  if (agent != null && agent.agent_id>0) {

    let data = await db.quotations.toArray();

    data.forEach((item) => {
      syncQuotation(tokenStr, token, item);
    });
  } else {
    // console.info("uploadQuotations: Agent NOT loaded.");
    debounceLeading(uploadQuotations(tokenStr, token), 5000);
  }
}

export { uploadQuotations };