<template>
  <b-card>
    <template v-slot:header>
      <b> <i class="fa fa-users"></i> TLU Types </b>
    </template>
    <b-card>
      <b-table striped hover :items="items" :fields="fields"></b-table>
    </b-card>
  </b-card>
</template>

<script>
import { db } from "@/store/db";

export default {
  data() {
    return {
      fields: [
        {
          key: "tlu_type_id",
          label: "ID",
        },
        {
          key: "tlu_type",
          label: "Type",
        },
        {
          key: "tlu",
          label: "TLU",
        },
      ],
      items: null,
    };
  },
  methods: {
    loadTLUTypes: async function () {
      this.items = await db.tlutypes.toArray();
    },
  },
  created() {
    this.loadTLUTypes();
  },
};
</script>